const comandaTheme = {
  colors: {
    primary: '#1AAD94',
    background: '#F4F5F7',
    backgroundSacola: '#F9FAFB',
    backgroundRodape: '#B3B3B3',
    closed: '#E94C3D',
    open: '#2DCC70',
    title: '#000000',
    titleWhite: '#fff',
    subtitle: '#333333',
    description: '#808080',
    backgroundCard: '#ffffff',
    categorias: ['#FFE384', '#D0A9F1', '#fbc2d0', '#BCED9F'],
  },

  heightCabecalho: 420,
  heightRodape: 60,
  borderRadiusButtons: '6px',
  borderRadius: '8px',
  borderRadiusCirculo: '50%',
  minHeightCardItem: 100,

  minHeightImgCategoria: 90,
};

export type ThemeType = typeof comandaTheme;

const theme: ThemeType = {
  colors: {
    primary: '#1AAD94',
    background: '#F4F5F7',
    backgroundSacola: '#F9FAFB',
    backgroundRodape: '#B3B3B3',
    closed: '#E94C3D',
    open: '#2DCC70',
    title: '#000000',
    titleWhite: '#fff',
    subtitle: '#333333',
    description: '#808080',
    backgroundCard: '#ffffff',
    categorias: ['#FFE384', '#D0A9F1', '#fbc2d0', '#BCED9F'],
  },

  heightCabecalho: 420,
  heightRodape: 60,
  borderRadiusButtons: '6px',
  borderRadius: '8px',
  borderRadiusCirculo: '50%',
  minHeightCardItem: 100,

  minHeightImgCategoria: 90,
};

export default theme;

/* eslint-disable max-classes-per-file */
/**
 * Models usados no App
 * @module Models
 * @category Utils
 */

import { CalendarValueType } from 'primereact/calendar';

export class UsuarioModel {
  id = undefined;
  login = '';
  idColaborador = undefined;
  idPapel = undefined;
  senha = '';
  dataCadastro = undefined;
  inativo = false;
}

export class AtletaModel {
  id = undefined;
  nome = '';
  stravaUsername = '';
  sexo = '';
  reliveId = 0;
  stravaId = 0;
  dataNascimento?: any = undefined;
  controleManual = true;
  ativo = true;
  metaKm = 0;
}

export class AtividadeAtletaModel {
  id = '';
  idAtleta = '';
  data: CalendarValueType = new Date();
  descricao = '';
  distancia = 0;
  tempoMovimento = 0;
  tempoMovimentoEmHoraMinuto?: any = undefined;
  velocidadeMedia = 0;
  velocidadeMaxima = 0;
  tipoAtividade = '';
  tipoAtividadeEsportiva = '';
  stravaId = '';
  reliveId = null;
  manual = true;
  atleta = new AtletaModel();
}

import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

  .title {
    flex: 1;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    margin-right: 4px;
  }

  .container-search {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }

  .button-voltar {
    padding: 0;
    height: 28px;
    width: 28px;
    font-size: 24px;
    background-color: ${({ theme }) => theme.colors.background};
    border-width: 0;
    margin: 2px;
    margin-right: 2px;
  }
`;

export const ContainerSearch = styled.div`
  flex: 1;
  max-width: 140px;
  text-align: end;
  /* font-size: 12px; */

  .buttons {
    float: right;
    /* height: 2.2em; */
    /* width: 2.2em; */
  }

  .search {
    width: 100px !important;
    /* height: 2.2em; */
  }

  /* 640px (40em x 16px) */
  @media (min-width: 40em) {
    max-width: 340px;
    .search {
      width: 300px !important;
    }
  }
`;

/**
 * Página Atividade
 * @module Atividade
 * @category Pages
 */

import { Column } from 'primereact/column';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';
import CalendarSp from '../../components/CalendarSp';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AtividadeAtletaService from '../../services/AtividadeAtletaService';
import AtletaService from '../../services/AtletaService';
import {
  getPageParams,
  errorHandle,
  calcNaxItemsPage,
  isScreenMobile,
  formatDate,
  formatFloat,
} from '../../util/functions';
import { StateScreen } from '../constants';
import AtividadesAtletaCrud from './crud';
import { Container } from './styles';

export default function Atividades() {
  // useSelectors
  const filterVisible = useSelector((state: any) => state.global.filterVisible);

  // useMemo
  const domParams = useParams();
  const [domSearch] = useSearchParams();

  const pageParams = useMemo(
    () => getPageParams(domParams, domSearch),
    [domParams, domSearch]
  );

  const filterService = useMemo(() => AtividadeAtletaService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/atividades';

  const [filter, setFilter] = useState(filterService);
  const [atividades, setAtividades] = useState([]);
  const [atleta, setAtleta] = useState(null);

  const [pageLimit, setPageLimit] = useState<number>(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState<NodeJS.Timeout>();

  const navigation = useNavigate();

  // useCallbacks
  const loadAtleta = useCallback(async (_nome: string) => {
    if (_nome !== undefined) {
      const r = await AtletaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map((e: any) => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(9, 15, 17);
    } else {
      ret = calcNaxItemsPage(12, 16, 20);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter: any, _page = 0, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await AtividadeAtletaService.findAll(_filter);
        setAtividades(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async (_filterValue: any) => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event: any) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async (_id: number) => {
      try {
        await AtividadeAtletaService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Atividade';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    (id: any) => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', (idx: any) => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o atividade digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page card">
      <div className="grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={false}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          titleFilterDefault="Buscar por Descrição"
          handleFilterDefault={(text) => {
            filter.descricao = text;
            setFilter({ ...filter, descricao: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              idAtleta: undefined,
            });
            setAtleta(null);
          }}
          handleButtonInsert={() => navigation('/atividades/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="col-12 sm:col-2 lg:col-2 p-fluid">
            <LabelSp> Período de:</LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              // locale={cfgPtBr}
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataInicio}
              yearNavigator
              yearRange="2010:2040"
              onChange={(e) => setFilterAndSearch({ ...filter, dataInicio: e.value })}
            />
          </div>
          <div className="col-12 sm:col-2 lg:col-2 p-fluid">
            <LabelSp>Até: </LabelSp>
            <CalendarSp
              appendTo={document.body}
              readOnlyInput
              id="dataFinal"
              dateFormat="dd/mm/yy"
              value={filter.dataFim}
              yearNavigator
              yearRange="2010:2040"
              onChange={(e) => setFilterAndSearch({ ...filter, dataFim: e.value })}
            />
          </div>
          <div className="col-12 sm:col-6 lg:col-5 p-fluid">
            <LabelSp>Atleta</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={(e) => {
                setFilterAndSearch({ ...filter, idAtleta: e?.value });
                setAtleta(e);
              }}
              value={atleta}
              onFilter={async (txtFilter) => {
                const retorno = await loadAtleta(txtFilter);
                return retorno;
              }}
            />
          </div>

          <div className="col-12 sm:col-3 lg:col-3 p-fluid">
            <LabelSp>Tipo(Manual/Auto)</LabelSp>
            <DropdownSp
              options={[
                { label: 'Todos', value: 'T' },
                { label: 'Manual', value: 'M' },
                { label: 'Auto', value: 'A' },
              ]}
              placeholder="Todos"
              onChange={(e) => {
                setFilterAndSearch({ ...filter, tipoManual: e?.value });
              }}
              value={filter.tipoManual}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <div className="col-12 p-fluid datatable-doc-demo">
        <DataTableSp
          value={atividades}
          style={{ marginBottom: '2px' }}
          paginator
          rows={pageLimit}
          lazy
          totalRecords={totalRecords}
          first={first}
          onPage={(event: any) => onPage(event)}
        >
          <Column
            field="data"
            className="grid-col-data"
            header="Data"
            body={(rowData) => formatDate(rowData.data, 'dd/MM/yyyy')}
          />
          <Column
            field="atletaId"
            className="grid-col grid-col-nowrap"
            header="Atleta"
            style={{ maxWidth: 150 }}
            body={(rowData) => rowData.atleta?.nome}
          />
          <Column
            field="tipoAtividadeEsportiva"
            className="grid-col"
            header="Tp. Ativ. Esport."
            style={{ width: 180 }}
          />

          <Column field="descricao" className="grid-col" header="Descrição" />
          <Column
            field="distancia"
            className="grid-col-curr"
            header="Distância (KM)"
            body={(rowData) => formatFloat(rowData.distancia, 3)}
          />
          <Column
            className="grid-col gid-col-acoes-35"
            bodyStyle={{ textAlign: 'end' }}
            body={renderButtonOp}
          />
        </DataTableSp>
      </div>
    );
  }

  function renderButtonOp(rowData: any) {
    return (
      <BotaoMenuGrid
        handles={[
          () => navigation(`/atividades/${rowData.id}?view`),
          () => navigation(`/atividades/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[false, false, false]}
      />
    );
  }

  function renderCrud() {
    return (
      <AtividadesAtletaCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          navigation(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}

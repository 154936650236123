/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

/**
 * Serviço que gerencia Usuario
 * @module Usuario
 * @category Serviços
 */

import { ConfigApi } from '../config/Constantes';
import { BaseService } from './BaseService';

class AtletaService extends BaseService {
  constructor() {
    super(`${ConfigApi.AtletaURL}`);
  }

  /**
   * Retorna o objeto filter específico do serviço
   * @returns {Filter}
   */
  getFilter(): Filter {
    return new Filter();
  }
}

/**
 * Objeto filtro do serviço do Usuario
 * @class
 */
class Filter {
  id?: string;
  nome?: string;
  limit: 99;
  page: 0;

  constructor() {
    this.id = '';
    this.nome = '';
    this.limit = 99;
    this.page = 0;
  }
}
export default new AtletaService();

/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import classNames from 'classnames';
import React from 'react';
// import { Link } from 'react-router-dom';

import logoDark from './assets/layout/images/logo-dark.png';

export const AppTopbar = (props: any) => {
  return (
    <div className="layout-topbar">
      <div className="layout-topbar-logo">
        <img
          src={
            props.layoutColorMode === 'light'
              ? logoDark
              : './assets/layout/images/logo-white.png'
          }
          alt="logo"
        />

        <div style={{ fontSize: 13, textAlign: 'center' }}>
          <span style={{ display: 'block' }}>Fone: (38)3016-0687</span>
          <a
            href="http://www.speedysistemas.com.br"
            target="_blank"
            title="Especialida em desenvolvimento Web/Mobile e Desktop"
            rel="noreferrer"
          >
            speedysistemas.com.br
          </a>
        </div>
      </div>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames('layout-topbar-menu lg:flex origin-top', {
          'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-power-off" />
            <span>Sair</span>
          </button>
        </li>

        {/* <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-cog" />
            <span>Settings</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-user" />
            <span>Profile</span>
          </button>
        </li> */}
      </ul>
    </div>
  );
};

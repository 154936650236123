/**
 * Página Login
 * @module Login
 * @category Pages
 */

import { Button } from 'primereact/button';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import packageJson from '../../../package.json';
import logo from '../../assets/images/logo300.png';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { loginRequest } from '../../store/modules/auth/actions';
import { Container } from './styles';

export default function Login() {
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');

  const dispatch = useDispatch();

  // use effects
  useEffect(() => {
    setLogin('');
    setPassword('');
  }, []);

  function handleSubmit(e: any) {
    e.preventDefault();
    dispatch(loginRequest(login, password, '/home'));
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div className="grid">
          <div className="col-12" style={{ textAlign: 'center' }}>
            <img className="logo" src={logo} alt="" />
          </div>

          <div className="col-12">
            <LabelSp>Login</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                type="text"
                name="Usuário"
                placeholder="Login"
                required
                value={login}
                onChange={(e: any) => setLogin(e.target.value)}
              />

              <span className="p-inputgroup-addon">
                <i className="pi pi-user" />
              </span>
            </div>
          </div>
          <div className="col-12">
            <LabelSp>Senha</LabelSp>
            <div className="p-inputgroup">
              <InputTextSp
                type="password"
                name="password"
                placeholder="sua senha"
                required
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <span className="p-inputgroup-addon">
                <i className="pi pi-key" />
              </span>
            </div>
          </div>

          <div className="col-12">
            <Button
              style={{ width: '100%' }}
              type="submit"
              label="Login"
              disabled={!(login && password)}
            />
          </div>

          <span
            style={{
              marginTop: 15,
              marginRight: 5,
              marginBottom: 0,
              flex: 1,
              textAlign: 'right',
            }}
          >
            Versão: {packageJson.version}
          </span>
        </div>
      </form>
    </Container>
  );
}

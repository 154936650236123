/* eslint-disable no-throw-literal */
/**
 * Serviço que gerencia a autenticação na plataforma
 * @module Auth
 * @category Serviços
 */

import { store } from '../store';
import { getDecodedToken } from '../util/functions';

/**
 * Classe do serviço de autenticação
 */
class AuthService {
  /**
   * Obtem e decodifica o token
   * @returns {Object} Retorna um objeto no formato:</br> {</br>
   *     signed: boolean,</br>
   *     exp: data da expiração,</br>
   *     Usuario: Usuario,</br>
   *     token: string contendo o token</br>
   *   }
   */
  acessToken(): any {
    const { token } = store.getState().auth;

    try {
      const decoded: any = getDecodedToken(token);

      return {
        signed: true,
        exp: decoded.exp,
        usuario: decoded.usuario,
        token,
      };
    } catch (error) {
      return {
        signed: false,
        exp: 0,
        token: '',
      };
    }
  }

  getUsuario(): any {
    try {
      const { decodedToken } = store.getState().auth;
      const { usuario, vendedorColaborador } = decodedToken;
      const usuarioLogado = {
        ...usuario,
        vendedorColaborador,
      };

      return usuarioLogado;
    } catch (error) {
      return null;
    }
  }

  getApiInfo(): any {
    const apiInfo = JSON.parse(localStorage.getItem('apiInfo') || '');
    return apiInfo;
  }

  checkRoles(arrayNames: string[] | string): any {
    const { decodedToken } = store.getState().auth;

    if (!decodedToken) {
      return false;
    }
    const { roles } = decodedToken;

    if (!Array.isArray(arrayNames)) {
      arrayNames = arrayNames ? [arrayNames] : [];
    }

    if (arrayNames.length === 0) {
      return true;
    }

    let ret = false;

    for (let x = 0; x < arrayNames.length; x++) {
      const e = arrayNames[x];
      if (!roles || !Array.isArray(roles)) {
        return false;
      }
      if (roles.includes(e)) {
        ret = true;
        return true;
      }
    }

    return ret;
  }
}

export default new AuthService();

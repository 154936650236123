/**
 * Componente Painel para receber componentes de filtros das telas de cadastro
 * @module PanelFilter
 * @category Componentes
 */

import React from 'react';
import { useSelector } from 'react-redux';

/**
 * @typeof PanelButtonsCrudProps
 * @property {boolean} visible Mostra ou não o PanelButtonsCrud
 */

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  children?: React.ReactNode;
}
export default function PanelFilter(props: IProps) {
  const { visible, children } = props;

  const filterVisible = useSelector((state: any) => state.global.filterVisible);

  if (filterVisible || visible) {
    return (
      <div {...props} style={{ margin: '0px', padding: '0px' }}>
        {children}
      </div>
    );
  }
  return null;
}

PanelFilter.defaultProps = {
  children: undefined,
  visible: false,
};

import { addSeconds } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import ButtonSp from '../../components/ButtonSp';
import CalendarSp from '../../components/CalendarSp';
import DropDownLazy from '../../components/DropdownLazy';
import InputCurrencySp from '../../components/InputCurrencySp';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AtividadeAtletaService from '../../services/AtividadeAtletaService';
import AtletaService from '../../services/AtletaService';
import {
  errorHandle,
  formatDate,
  isNumber,
  newDateTimeStartOnly,
  validateFields,
} from '../../util/functions';
import { AtividadeAtletaModel } from '../../util/Models';
import { StateScreen } from '../constants';

interface IProps {
  stateScreen: string;
  idSelected: string;
  onClose: (ret?: any) => void;
}
export default function AtletaCrud(props: IProps) {
  const { stateScreen, idSelected, onClose } = props;

  interface IDropDown {
    label: string;
    value: any;
  }
  // states
  const [atividadeAtleta, setAtividadeAtleta] = useState(new AtividadeAtletaModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [atletaSelecionado, setAtletaSelecionado] = useState<IDropDown | null>(null);
  // useCallbacks
  const loadAtleta = useCallback(async (_nome: string) => {
    if (_nome !== undefined) {
      const r = await AtletaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map((e: any) => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async (_id: string) => {
    try {
      const retorno = await AtividadeAtletaService.findById(_id);
      if (isNumber(retorno.tempoMovimento)) {
        const dataFinal = newDateTimeStartOnly();
        retorno.tempoMovimentoEmHoraMinuto = addSeconds(dataFinal, retorno.tempoMovimento);
      }

      retorno.data = new Date(retorno.data);

      setAtividadeAtleta(retorno);
      setAtletaSelecionado({
        label: retorno.atleta.nome,
        value: retorno.atleta.id,
      });

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  // events

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', (idx: number) => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno: any;
      const atividadeSalvar = { ...atividadeAtleta };
      const tempo = atividadeSalvar.tempoMovimentoEmHoraMinuto.getTime
        ? formatDate(atividadeSalvar.tempoMovimentoEmHoraMinuto, 'HH:mm')
        : '00:00';
      const hora = Number(tempo.split(':')[0]);
      const minuto = Number(tempo.split(':')[1]);

      atividadeSalvar.tempoMovimento = hora * 60 * 60 + minuto * 60;

      if (stateScreen === StateScreen.stInsert) {
        retorno = await AtividadeAtletaService.insert(atividadeSalvar);
      } else {
        retorno = await AtividadeAtletaService.update(atividadeSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new AtividadeAtletaModel();

      const dataFinal = newDateTimeStartOnly();
      novo.tempoMovimentoEmHoraMinuto = addSeconds(dataFinal, 0);

      setAtividadeAtleta(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <div className="col-12 sm:col-2 lg:col-2 p-fluid">
        <LabelSp>Data</LabelSp>
        <CalendarSp
          appendTo={document.body}
          readOnlyInput
          // locale={cfgPtBr}
          dateFormat="dd/mm/yy"
          value={atividadeAtleta.data}
          yearNavigator
          yearRange="2010:2040"
          onChange={(e) => {
            if (!Array.isArray(e)) setAtividadeAtleta({ ...atividadeAtleta, data: e.value });
          }}
        />
      </div>
      <div className="col-12 sm:col-5 lg:col-5 p-fluid">
        <LabelSp>Atleta</LabelSp>
        <DropDownLazy
          autoLoad
          disabled={viewMode() || idSelected !== ''}
          placeholder="Selecione"
          required
          onChange={(e) => {
            const atleta: any = e ? { id: e.value, nome: e.label } : null;
            setAtividadeAtleta({
              ...atividadeAtleta,
              atleta,
              idAtleta: e?.value,
            });
            setAtletaSelecionado(e);
          }}
          value={atletaSelecionado}
          onFilter={async (txtFilter) => {
            const retorno = await loadAtleta(txtFilter);
            return retorno;
          }}
        />
      </div>

      <div className="col-12 sm:col-5 lg:col-5 p-fluid">
        <LabelSp>Descrição</LabelSp>
        <InputTextSp
          disabled={viewMode()}
          value={atividadeAtleta.descricao}
          maxLength={100}
          required
          type="text"
          onChange={(e) =>
            setAtividadeAtleta({
              ...atividadeAtleta,
              descricao: e.target.value,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Distância(KM)</LabelSp>
        <InputCurrencySp
          disabled={viewMode()}
          value={atividadeAtleta.distancia}
          required
          digits={3}
          onChangeNumber={(_e, v) =>
            setAtividadeAtleta({
              ...atividadeAtleta,
              distancia: v,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-3 lg:col-3 p-fluid">
        <LabelSp>Velocidade Média</LabelSp>
        <InputCurrencySp
          disabled={viewMode()}
          value={atividadeAtleta.velocidadeMedia}
          digits={3}
          onChangeNumber={(_e, v) =>
            setAtividadeAtleta({
              ...atividadeAtleta,
              velocidadeMedia: v,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-4 lg:col-4 p-fluid">
        <LabelSp>Velocidade Máxima</LabelSp>
        <InputCurrencySp
          disabled={viewMode()}
          value={atividadeAtleta.velocidadeMaxima}
          digits={3}
          onChangeNumber={(_e, v) =>
            setAtividadeAtleta({
              ...atividadeAtleta,
              velocidadeMaxima: v,
            })
          }
        />
      </div>

      <div className="col-12 sm:col-2 lg:col-2 p-fluid">
        <LabelSp>Tempo</LabelSp>
        <CalendarSp
          appendTo={document.body}
          readOnlyInput
          dateFormat="dd/mm/yy"
          value={atividadeAtleta.tempoMovimentoEmHoraMinuto}
          timeOnly
          onChange={(e) =>
            setAtividadeAtleta({ ...atividadeAtleta, tempoMovimentoEmHoraMinuto: e.value })
          }
        />
      </div>

      <div className="col-12 lg:col-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            disabled={!validateFields(atividadeAtleta, ['idAtleta', 'distancia', 'descricao'])}
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

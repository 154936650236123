/* eslint-disable jsx-a11y/label-has-associated-control */

/**
 * Componente Label personalizado
 * @module LabelSp
 * @category Componentes
 */

import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLLabelElement> {
  // eslint-disable-next-line react/require-default-props
  style?: React.CSSProperties | undefined;
  children: string;
}
/**
 * Componente LabelSp
 * @func LabelSp
 * @param props Propriedades
 */
export default function LabelSp(props: IProps & React.HTMLAttributes<HTMLLabelElement>) {
  const { style, children, ...rest } = props;
  const styleLocal = style || {};
  return (
    <div style={{ paddingBottom: 2 }}>
      <label style={{ fontWeight: 'bold', ...styleLocal }} {...rest}>
        {children}
      </label>
    </div>
  );
}
LabelSp.defaultPropos = {
  style: undefined,
};

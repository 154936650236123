/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */

/**
 * Serviço que gerencia Usuario
 * @module Usuario
 * @category Serviços
 */

import { addMonths } from 'date-fns';

import { ConfigApi } from '../config/Constantes';
import { BaseService } from './BaseService';

class AtividadeAtletaService extends BaseService {
  constructor() {
    super(`${ConfigApi.AtividadeAtletaURL}`);
  }

  /**
   * Retorna o objeto filter específico do serviço
   * @returns {Filter}
   */
  getFilter(): Filter {
    return new Filter();
  }
}

/**
 * Objeto filtro do serviço do Usuario
 * @class
 */
class Filter {
  id?: string;
  idAtleta?: string;
  dataInicio?: Date;
  dataFim?: Date;
  descricao?: string;
  tipoManual?: string;
  limit: 99;
  page: 0;

  constructor() {
    this.id = '';
    this.idAtleta = '';
    this.descricao = '';
    this.dataInicio = addMonths(new Date(), -6);
    this.dataFim = addMonths(new Date(), +1);
    this.tipoManual = 'T';
    this.limit = 99;
    this.page = 0;
  }
}
export default new AtividadeAtletaService();

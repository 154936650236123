/**
 * Módulo de Serviço base para demais serviços
 * @module BaseService
 * @category Serviços
 */

import { store } from '../store';
import { loading, loadingSuccess } from '../store/modules/global/actions';
import { geraFiltroHttp } from '../util/functions';
import api from './api';

/**
 * Classe base service
 */
export class BaseService {
  url = '';

  /**
   * Construtor da clase base
   * @param urlService Url que será geranciada pelo controller para conexão com a API
   */
  constructor(urlService: string) {
    this.url = urlService;
  }

  /**
   * Usada para mostrar uma tela de animação enquanto algo é carregado
   * @param {boolean} value Ativa ou desativa a tela de animação
   */
  setLoading(value: boolean): void {
    if (value) {
      store.dispatch(loading());
    } else {
      store.dispatch(loadingSuccess());
    }
  }

  /**
   * Função para buscar uma lista de objetos da API usando método get.
   * @async
   * @param {Object} filter Objeto contendo os filtros;
   * @returns {Promise<any>}
   */
  async findAll(filter: any): Promise<any> {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await api.get(`${this.url}${filterGet}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Busca dados da API da API retornando um objeto.
   * @async
   * @param {string} id do recurso a ser buscado.
   * @returns {Promise<any>}
   */
  async findById(id: string): Promise<any> {
    this.setLoading(true);
    try {
      const response = await api.get(`${this.url}/${id}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Função para para dar um post na API
   * @async
   * @param {object} body Objeto a ser enviado no corpo da requisição
   * @returns {Promise<any>} Retorna uma promise contendo o objeto retornado da api
   */
  async insert(body: any): Promise<any> {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await api.post(this.url, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Função para para dar um put na API
   * @async
   * @param {object} body Objeto a ser enviado no corpo da requisição
   * @returns {Promise<any>} Retorna uma promise contendo o objeto retornado da api
   */
  async update(body: any): Promise<any> {
    try {
      const response = await api.put(`${this.url}/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  /**
   * Função para excluir um registro da API usando método delete.
   * @async
   * @param id Id do recurso
   */
  async delete(id: number): Promise<any> {
    try {
      await api.delete(`${this.url}/${id}`);
    } finally {
      this.setLoading(false);
    }
  }
}

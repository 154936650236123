/**
 * Componente customizado baseado no primereact/calendar
 * @module CalendarSp
 * @category Componentes
 */
import { Calendar, CalendarProps } from 'primereact/calendar';
import React from 'react';

interface IProps extends CalendarProps {
  required?: boolean;
  className?: string;
  disabled?: boolean;
}

function CalendarSp(props: IProps) {
  const { value, required, disabled, className, ...rest } = props;
  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <Calendar {...rest} disabled={disabled} value={value || ''} className={classNames} />;
}

CalendarSp.defaultProps = {
  required: false,
  disabled: false,
  className: '',
};

export default CalendarSp;

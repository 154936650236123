/* eslint-disable react/no-array-index-key */
/**
 * Módulo de configuração das rotas da plataforma
 * @module Routes
 * @category Rotas
 */

import React from 'react';

import AcessDenied from '../pages/AcessDenied';
import Atividades from '../pages/Atividades';
import Atletas from '../pages/Atletas';
import Home from '../pages/Home';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Switch from './Switch';

/**
 * Componente de rotas
 * @func Routes
 */
export default function Routes() {
  const routes: any[] = [];

  routes.push({ path: '/', element: <Login /> });
  routes.push({ path: '/login', element: <Login /> });
  routes.push({ path: '/home', element: <Home />, isPrivate: true });
  routes.push({ path: '/atividades', element: <Atividades />, isPrivate: true });
  routes.push({ path: '/atividades/:option', element: <Atividades />, isPrivate: true });

  routes.push({ path: '/atletas', element: <Atletas />, isPrivate: true });
  routes.push({ path: '/atletas/:option', element: <Atletas />, isPrivate: true });

  routes.push({ path: '/denied', element: <AcessDenied />, isPrivate: true });
  routes.push({ path: '*', element: <NotFound />, isPrivate: true });

  return <Switch routes={routes} />;
}

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable global-require */

/**
 * Página Home
 * @module Home
 * @category Pages
 */
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

import logoSpeedy from '../../assets/images/logo-speedy.png';
import AuthService from '../../services/AuthService';
import { Container } from './styles';

// import ButtonSp from '../../components/ButtonSp';

export default function Home() {
  const paddingTop = (window.innerHeight - 300) / 2 - 20;
  const logo = require('../../assets/images/logo.png');
  const [show, setShow] = useState(false);

  const nomeUsuario = AuthService.getUsuario()?.login;

  return (
    <Container>
      <Dialog
        closable
        closeOnEscape
        header="Reunião"
        visible={show}
        contentStyle={{ margin: 0, padding: 0 }}
        style={{ minWidth: '350px', width: '95%' }}
        modal
        blockScroll
        onHide={() => {
          if (setShow) {
            setShow(false);
          }
        }}
      >
        <iframe
          allow="camera; microphone; display-capture"
          src="https://meet.jit.si/reuniaoallankrdec"
          style={{ width: '100%', border: 0 }}
        />
      </Dialog>

      <div>
        <strong>{`Usuário: ${nomeUsuario}`}</strong>
      </div>
      <div className="grid" style={{ margin: 0, padding: 0, paddingTop }}>
        <div className="col-12">
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <img alt="Logo" src={logo} style={{ maxWidth: '90%', maxHeight: '100%' }} />
          </div>
        </div>
      </div>
      <div className="logo-speedy">
        <span>Desenvolvido por:</span>
        <img alt="Logo Speedy" src={logoSpeedy} />

        <a
          href="http://www.speedysistemas.com.br"
          target="_blank"
          title="Especialida em desenvolvimento Web/Mobile e Desktop"
        >
          www.speedysistemas.com.br
        </a>
      </div>
    </Container>
  );
}

/**
 * Módulo que cria o menu da aplicação conforme perfil de usuário
 * @module Menu
 * @category Pages
 */

export default function createMenu(): any[] {
  // const navigate = useNavigate();

  const menuReturn: any = [
    {
      label: 'Menu',
      items: [
        {
          label: 'Home',
          title: 'Home',
          icon: 'pi pi-fw pi-home',
          to: '/home',
        },
        {
          label: 'Atletas',
          title: 'Atletas',
          icon: 'pi pi-fw pi-user',
          to: '/atletas',
        },
        {
          label: 'Atividades',
          title: 'Atividades',
          icon: 'pi pi-fw pi-table',
          to: '/atividades',
        },
      ],
    },
  ];

  return menuReturn;
}

/* eslint-disable react/destructuring-assignment */
/**
 * Componente customizado baseado no componente primereact/datatable
 * @module DataTableSp
 * @category Componentes
 */

import { DataTable } from 'primereact/datatable';
import React from 'react';

import { ContainerGrid } from './styles';

/**
 * Componente DataTableSp
 * @func DataTableSp
 */
export default function DataTableSp(props: any) {
  return (
    <ContainerGrid>
      <DataTable
        stripedRows
        showGridlines
        style={{ marginBottom: '2px' }}
        paginator
        emptyMessage="Nenhum registro encontrado"
        currentPageReportTemplate={
          props.totalRecords ? 'Mostrando {first} a {last} de um total de {totalRecords}' : ''
        }
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        {...props}
      >
        {props.children}
      </DataTable>
    </ContainerGrid>
  );
}

/* eslint-disable no-throw-literal */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */

import { put, all, takeLatest } from 'redux-saga/effects';

// eslint-disable-next-line import/no-cycle

import api from '../../../services/api';
import { getDecodedToken, errorHandle } from '../../../util/functions';
import { hiddenDialogLogin } from '../dialog/actions';
import { loadingSuccess, loading } from '../global/actions';
import { loginSuccess } from './actions';
// eslint-disable-next-line import/no-cycle

// interface IResponseGenerator {
//   config?: any;
//   data?: any;
//   headers?: any;
//   request?: any;
//   status?: number;
//   statusText?: string;
// }

export function* doLogin({ payload, redirectTo }: any): any {
  yield put(loading());
  const { login, password } = payload;

  try {
    if (login !== 'administrador' || password !== 'strava123') {
      throw 'Usuário ou senha inválido';
    }
    const { token, usuario } = {
      usuario: {
        id: 1,
        login: 'administrador',
      },
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlJbmZvIjp7InZlcnNpb25OdW1iZXIiOjI5MSwidmVyc2lvbiI6IjIuOS4xIn0sInJvbGVzIjpbXSwiY29uZmlndXJhY2FvIjp7ImlkIjoxLCJ2YWxvclNhbGFyaW9WaWdlbnRlIjoxMjEyfSwidXN1YXJpbyI6eyJpZCI6IiIsIm5vbWUiOiJhZG1pbmlzdHJhZG9yIn0sImlhdCI6MTY5ODE2MjU1OCwiZXhwIjoxMDMzODA3NjE1OH0.DAKM8eo5_j30Z26bnPNH3cJh4AQ807wcMhm5B9h0Kcw',
    };

    const decodedToken: any = getDecodedToken(token);

    localStorage.setItem(
      'apiInfo',
      JSON.stringify({
        apiVersion: 1,
        versionText: '1',
        dateLastUpdate: new Date(),
      })
    );

    const usuarioLogado = {
      ...usuario,
      vendedorColaborador: decodedToken?.vendedorColaborador,
    };

    yield put(loginSuccess(token, decodedToken, usuarioLogado));
    yield put(loadingSuccess());
    yield put(hiddenDialogLogin());

    if (redirectTo && redirectTo !== '') {
      window.location = redirectTo; // força recarregar a pagina
    }
  } catch (err: any) {
    yield put(loadingSuccess());
    errorHandle(err);
  }
}
export function setToken({ payload }: any): any {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function doLogout(): any {
  // if (caches) {
  //   caches.keys().then(names => {
  //     for (const name of names) {
  //       // caches.delete(name);
  //     }
  //   });
  // }
  // setTimeout(() => window.location.reload(), 600);
  setTimeout(() => (window.location.href = '/login'), 400);
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', doLogin),
  takeLatest('@auth/LOGOUT', doLogout),
]);

/* eslint-disable no-return-assign */
/**
 * Componente contendo um cabeçalho padrão com botões, espaço para filtro
 * @module CrudHeader
 * @category Componentes
 */

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { showFilter, hideFilter } from '../../store/modules/global/actions';
import InputTextSp from '../InputTextSp';
import PanelFilter from '../PanelFilter';
import { montaMenuGrid } from '../utils/utils';
import { Container, ContainerSearch } from './styles';

/**
 * @typedef OptionsButtonMenu
 * @property {Function[]} handles
 * @property {string[]} labels
 * @property {string[]} icons
 * @property {boolean[]} disableds
 */

/**
 * @typedef CrudHeaderProps
 */

interface IOptionsButtonMenu {
  handles: (() => void)[];
  labels: string[];
  icons: string[];
  disableds: boolean[];
}

interface IProps {
  title: string;
  children?: React.ReactNode;
  handleButtonInsert: () => void;
  showButtonInsert: boolean;
  titleFilterDefault: string;
  handleFilterDefault: (filterName: string) => void;
  disabledButtonInsert: boolean;
  handleClearFilters: () => void;
  handleButtonSearch: () => void;

  showButtonFilter: boolean;
  showButtonConfig?: boolean;

  optionsButtonMenu?: IOptionsButtonMenu;
}

/**
 * Componente CrudHeader
 * @func CrudHeader
 * @param props Propriedades
 */
export default function CrudHeader(props: IProps) {
  const [filterDefault, setFilterDefault] = useState('');
  const [funcTimeOut, setFuncTimeOut] = useState<NodeJS.Timeout>();

  const dispatch = useDispatch();
  const filterVisible = useSelector((state: any) => state.global.filterVisible);
  function handleFilterUpdate() {
    if (filterVisible) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  }

  const {
    showButtonInsert,
    handleButtonInsert,
    disabledButtonInsert,
    handleButtonSearch,
    titleFilterDefault,
    handleFilterDefault,
    handleClearFilters,
    showButtonFilter,
    optionsButtonMenu,
    showButtonConfig,
    title,
    children,
  } = props;

  const montaMenuItem = useCallback(() => {
    if (optionsButtonMenu) {
      const { disableds, handles, icons, labels } = optionsButtonMenu;
      return montaMenuGrid(handles, disableds, labels, icons);
    }
    return null;
  }, [optionsButtonMenu]);

  const autoSearch = useCallback(
    async (_filter: any) => {
      if (handleFilterDefault) {
        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleFilterDefault(_filter);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [funcTimeOut, handleFilterDefault]
  );

  let menu: any = null;

  return (
    <>
      <Container className="col-12">
        <span className="title">{title}</span>
        <hr style={{ marginTop: 2 }} />
      </Container>
      {showButtonFilter && (
        <>
          <div className="col-12">
            {showButtonInsert && (
              <Button
                className="buttons"
                title="Inserir"
                label="Inserir"
                icon="pi pi-plus-circle"
                type="button"
                onClick={() => {
                  if (handleButtonInsert) {
                    handleButtonInsert();
                  }
                }}
                disabled={disabledButtonInsert}
              />
            )}

            {optionsButtonMenu && (
              <>
                <Button
                  className="p-button-secondary buttons"
                  title="Opções"
                  label="Opções"
                  icon="pi pi-list"
                  type="button"
                  onClick={(event) => {
                    menu.toggle(event);
                  }}
                />
                <Menu
                  model={montaMenuItem()}
                  popup
                  ref={(el) => (menu = el)}
                  id="popup_menu"
                  appendTo={document.body}
                />
              </>
            )}

            {showButtonConfig && (
              <Button
                style={{ float: 'right', marginRight: 0 }}
                className="p-button-success buttons"
                title="Filtros Adicionais"
                icon="pi pi-filter"
                onClick={() => handleFilterUpdate()}
              />
            )}
            <ContainerSearch
              className="p-inputgroup"
              style={{ float: 'right', marginRight: 5 }}
            >
              {titleFilterDefault && (
                <InputTextSp
                  className="search"
                  placeholder={titleFilterDefault}
                  maxLength={40}
                  value={filterDefault}
                  onChange={(e) => {
                    setFilterDefault(e.target.value);
                    autoSearch(e.target.value);
                  }}
                />
              )}
              {titleFilterDefault && (
                <Button
                  className="p-button-secondary buttons"
                  title="Buscar"
                  icon="pi pi-search"
                  type="button"
                  onClick={() => {
                    if (handleFilterDefault) {
                      handleFilterDefault(filterDefault);
                    }
                  }}
                />
              )}
            </ContainerSearch>
          </div>

          {filterVisible && showButtonConfig && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleButtonSearch();
              }}
            >
              <PanelFilter className="grid col-12">{children}</PanelFilter>
              <div className="col-12 lg:col-12">
                <Button
                  className="p-button-secondary"
                  title="Buscar"
                  type="submit"
                  icon="pi pi-search"
                  label="Buscar"
                />
                <Button
                  className="p-button-warning"
                  title="Limpar Filtros"
                  icon="pi pi-times-circle"
                  onClick={() => {
                    if (handleClearFilters) {
                      setFilterDefault('');
                      handleClearFilters();
                    }
                  }}
                  label="Limpar"
                />
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}

CrudHeader.defaultProps = {
  showButtonConfig: true,
  optionsButtonMenu: null,
  children: {},
};

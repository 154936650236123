/**
 * Componente customizado baseado no primereact/dropdown
 * @module DropdownSp
 * @category Componentes
 */

import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React from 'react';

type IProps = DropdownProps;

/**
 * Componente DropdownSp
 * @func DropdownSp
 * @param {DropdownSpProps} props Propriedades
 */
export default function DropdownSp(props: IProps) {
  const { required, className, disabled, ...rest } = props;

  const classNames = required && !disabled ? `p-error ${className || ''}` : className || '';
  return <Dropdown {...rest} disabled={disabled} className={classNames} />;
}

DropdownSp.defaultProps = {
  required: false,
  className: '',
  disabled: false,
};
